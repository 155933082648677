import React, {useEffect} from "react";
import './header.scss';
import {Container, Nav, Navbar} from 'react-bootstrap';
import {Link} from 'react-router-dom';

function Header(){  
    
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
        window.removeEventListener("scroll", isSticky);
        };
    });

    const isSticky = (e: any) => {
        const header = document.querySelector(".header");
        const scrollTop = window.scrollY;
        scrollTop >= 50 ? header?.classList.add("header-sticky") : header?.classList.remove("header-sticky");
      };  
      
      
      const handleClickScroll = (e:any) => {
        console.log(e)
        const element = document.getElementById(e);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };


    return(
        <div className="header">
            <Navbar sticky="top" bg="white" expand="lg">
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <div className="logoView">
                        <Navbar.Brand><img src="/images/logo.svg" alt="Akhil Bharatiya Prathibha Utthan Abhiyan" /></Navbar.Brand>
                    </div>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <div className="logoSideMenuView d-flex d-lg-none">
                                <Navbar.Toggle aria-controls="basic-navbar-nav" className="menuClose" />
                                <Navbar.Brand><img src="/images/logo.svg" alt="Akhil Bharatiya Prathibha Utthan Abhiyan" /></Navbar.Brand>
                            </div>
                            <div className="headerMenuLinks">
                                <Link onClick={() => handleClickScroll('home')} to={""}>Home</Link>

                                <Link  onClick={() => handleClickScroll('aboutus')} to={""}>About Us</Link>

                                <Link  onClick={() => handleClickScroll('missionvision')} to={""}>Mission & Vision</Link>

                                <Link  onClick={() => handleClickScroll('projects')} to={""}>Our Projects</Link>

                                <Link  onClick={() => handleClickScroll('media')} to={""}>Media</Link>
                            </div>
                            <div className="ctaBtn"><Link onClick={() => handleClickScroll('contactus')} to={""}>Contact Us</Link></div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}
export default Header;
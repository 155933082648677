import React, { } from 'react';
import './App.scss';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

function Homepage() {

  const handleClickScroll = (e: any) => {
    console.log(e)
    const element = document.getElementById(e);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  // const [isActive, setIsActive] = useState(false);
  //   const [myClass, setMyClass] = useState(false);
  //   function handleClick(value: any) {
  //       setIsActive(current => !current);
  //       setMyClass(current => !current);
  //   };

  // const homeRef = useRef<any>(null);
  // const aboutusRef = useRef<any>(null);
  // const missionvisionRef = useRef<any>(null);
  // const ourprojectsRef = useRef<any>(null);
  // const ourmediaRef = useRef<any>(null);
  // const elpages:any = {
  //     '#home': homeRef,
  //     '#about': aboutusRef,
  //     '#missionvision': missionvisionRef,
  //     '#ourprojects': ourprojectsRef,
  //     '#ourmedia': ourmediaRef
  // }

  // useEffect(() => {
  //     const hash = window.location.hash;
  //     // debugger;
  //     try {
  //         elpages[hash].current.scrollIntoView({ behavior: 'smooth' });
  //     }
  //     catch (e) { 
  //       // debugger;
  //     }
  // }, []);





  return (
    <div className="app">
      <section id='home' className='mainBanner'>
        <div className='bannerBgView'>
          <Container>
            <div className="bannerTwo">
              <div>
                <h2>Empowering high quality <span>educational endeavours</span></h2>
                <p><strong>ABPUA</strong> believes in common but differentiated responsibility in cultivating, nurturing, fostering talent, merit & knack at Pan-India basis.</p>
                <div className="ctaBtn"><Link onClick={() => handleClickScroll('contactus')} to={""}>Contact Us</Link></div>
              </div>
              <div>
                <span>
                  <img src='/images/vdoThumb.jpg' alt='ABPUA' />
                  {/* <a href="javascript:void(0);"><img src='/images/vdoBtn.svg' alt='Play Video' /></a> */}
                </span>
              </div>
            </div>
          </Container>
        </div>
      </section>
      <section id='aboutus'>
        <div className="aboutABPUA">
          <Container>
            
            <div className="divTwo">
              <div>
              <h2>About ABPUA</h2>
              <p>
                <strong>Akhil Bharatiya Prathibha Utthan Abhiyan (ABPUA)</strong>, is a prestigious society established by the distinguished alumni and faculties of the esteemed Indian Institutes of Technology. <br/><br/>

The society's aim is to provide a level-playing field for the underprivileged students of the country and equip them with the skills and methodology to compete with the country's best as one of them in the various competitive examinations all over towards premier institutes of technology, management, medicine, law.</p></div>
              <div>
                <img src='/images/aboutImg.jpg' alt="About ABPUA" />
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section id='coreMember'>
        <div className="coreMem">
          <Container>            
           <div className="gridsCore">
            <h2>Core Members</h2>
            <ul>
            <li>
                <img  src="/images/coremem/skSharma.jpg" alt="Prof. S. K. Sharma" />
                <b>Prof. S. K. Sharma</b>
                <i>(Retd. Dean, IIT BHU)</i>
                <em>Chairman</em>
              </li>
              <li>
                <img  src="/images/coremem/tnSingh.jpg" alt="Dr. T. N. Singh" />
                <b>Dr. T. N. Singh</b>
                <i>(Director, IIT Patna)</i>
                <em>Vice Chairman</em>
              </li>
              <li>
                <img  src="/images/coremem/shashankChaturvedi.jpg" alt="Shri Shashank Chaturvedi" />
                <b>Shri Shashank Chaturvedi</b>
                <i>(IIT BHU)</i>
                <em>Secretary</em>
              </li>
              <li>
                <img  src="/images/coremem/ssmurthy.jpg" alt="Prof. S. S. Murthy" />
                <b>Prof. S. S. Murthy</b>
                <i>(Retd. Electrical Department, IIT Delhi)</i>
                <em>Chief Patron</em>
              </li>
              <li>
                <img  src="/images/coremem/hcVerma.jpg" alt="Prof. H.C. Verma" />
                <b>Prof. H.C. Verma</b>
                <i>(Dept of Physics, IIT Kanpur)</i>
                <em>Distinguished Member</em>
              </li>
              <li>
                <img  src="/images/coremem/krishnaKantShukla.jpg" alt="Dr. Krishna Kant Shukla" />
                <b>Dr. Krishna Kant Shukla</b>
                <i>(Physicist, Musician, Ecologist)</i>
                <em>Distinguished Member</em>
              </li>
              <li>
                <img  src="/images/coremem/kripaShanker.jpg" alt="Prof. Kripa Shanker" />
                <b>Prof. Kripa Shanker</b>
                <i>(ex. Deputy Director, IIT Kanpur)</i>
                <em>Distinguished Member</em>
              </li>
              <li>
                <img  src="/images/coremem/kmSingh.jpg" alt="Prof. K.M. Singh" />
                <b>Prof. K.M. Singh</b>
                <i>(Dept of Mechanical and Industrial Engineering, IIT Roorkee)</i>
                <em>Distinguished Member</em>
              </li>
            </ul>
           </div>
          </Container>
        </div>
      </section>

      <section id='missionvision' className='bgNew'>
        <div className="missionVision">
          <Container>
            <div className="columnhalf">
              <div>
                <img src="/images/missionIcon.svg" alt="Mission" />
                <h3>Our Mission</h3>
                <p>ABPUA is committed to serving the nation's children, through high quality educational endeavors, empowering them to compete at the highest level irrespective of their socio-economic background. We solicit you to participate towards building a humane, just and pluralistic society, by creating  exciting opportunities, with a commitment to  excellence.</p>
              </div>

              <div>
                <img src="/images/visionIcon.svg" alt="Vision" />
                <h3>Our Vision</h3>
                <p>To ensure that justice and equity must reach everyone in general and poor, deprived and disadvantage section in particular and without separation, demarcation and discrimination, ABPUA aims in conserving, replenishing and scaling up neglected, overlooked, disregarded & moribund streams of lore, knowledge and wisdom.</p>
              </div>
            </div>

            <div className="columnFull">
              <div>
                <img src="/images/charter.svg" alt="Charter" />
                <h3>Our Charter</h3>
                <p>ABPUA believes in common but differentiated responsibility in cultivating, nurturing, fostering and promoting distinctive, eclectic and assorted unevolved and underdeveloped flair, aptitude, special faculty, talent, merit & knack at Pan-India basis. It aims to minimize and eradicate urban-convent, class, caste, gender, race, domicile, region and IQ centric bias, prejudice and parti pris.<br /><br />

                  It aims to complement and supplement the national and humanitarian objectives, enshrined in the Indian constitution and being manifested from time to time through the policies, programmes and schemes of the government and ruling dispensation by leveraging upon strides in information and communication technology; socially sensitive and empathetic domain experts and dovetailing it with generous endowments including CSR funds, support from respective governments, other international and national organisations and agencies.</p>
              </div>


            </div>


          </Container>
        </div>
      </section>

      <section id='projects' >
        <div className="projects">
          <Container>
            <h2>ABPUA Projects</h2>
            <p>IITs are the premier institutes of India. IITs represent the pinnacle of professional success. The first mission undertaken by ABPUA is to enable Pratibhavan students across India to compete at a level ground in the IIT- JEE examination. This is one mission which is not only the beginning of efforts but will remain one of the highest priority projects of the organization at any future time.<br/><br/>ABPUA's latest initiative 'FACT' is a pan India mentoring ecosystem.</p>
            <div className="factDtls">
              <div><img src='/images/factScreen.png' alt='FACT' /></div>
              <div>
                <h2>FACT</h2>
                <p>FACT', an extraordinary platform dedicated to counselling and fostering networks among students, faculties, and parents. As the first of its kind, this platform enables various forms of mentoring, including peer-to-peer, senior-to-junior, faculty-to-student, faculty-to-parent, and even community mentoring. <br /><br />

                  Recognizing the critical junctures in students' educational journeys where they often face the daunting task of choosing the most suitable path, FACT aims to ensure that no student is left behind due to a dearth of knowledge or proper guidance. Our paramount objective is to empower every child to attain their envisioned career goals</p>
                <span><Link to={'https://factapp.in/'} target={"_blank"}>Visit Website <img src="/images/arrowGo.svg" alt='Visit Website' /></Link></span>
              </div>


            </div>

            <div className="boxContainer">
              <div className="boxPro">
                <img src="/images/jawaharNavodaya.jpg" alt="" />
                <b>Jawahar Navodaya Vidyalaya</b>
                <p>ABPUA conducted Talent Search Examination (TSE) along with the Navodaya Vidyalaya Samiti (NVS) in all the Jawahar Navodaya Vidyalayas. The project was to select students to grant over 1000 scholarships to deserving students.</p>
              </div>
              <div className="boxPro">
                <img src="/images/kendriyaVidyalaya.jpg" alt="" />
                <b>Kendriya Vidyalaya</b>
                <p>ABPUA conducted Talent Search Examination (TSE) along with the Kendriya Vidyalaya Sangathan (KVS) in all the Jawahar Kendriya Vidyalayas. The project was to select students to grant over 1000 scholarships to deserving students.</p>
              </div>
            </div>

            <div className="boxContainer">

              <div className="boxPro hgtFixed">
                <img src="/images/iitBhu.jpg" alt="" />
                <b>Teachers Training at IIT-BHU, Varanasi</b>
                <p>Students of different regions were escorted by selected physics, chemistry and math teachers of JNVs to the meet at BHU-IT.<br/><br/>This was a golden opportunity for these teachers to get IIT-JEE training. ABPUA conducted a 4 week workshop for all selected students from the previous talent search examinations at IT-BHU, Varanasi. The workshop had the presence of eminent faculties from leading institutions and professional coaching centres, coupled with some personality development programs.</p>
              </div>
              <div className="boxPro hgtFixed">
                <img src="/images/iitBhu2.jpg" alt="" />
                <b>Workshops at IIT-BHU, Varanasi</b>
                <p>All the selected students of JNV received complete guidance to succeed in IIT-JEE 2007/8. These students got a structured IIT-JEE distance learning course in addition to classroom crash courses.<br/><br/>The best IIT-JEE faculty across India came together on ABPUA's invitation to teach Navodaya students. Spanning over a month, students of NVs got invaluable guidance from the experts which enabled them to compete in IIT-JEE 2007.</p>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section className='bgNew'>
        <div className="ourValues">
          <Container>
            <h2>Our Values</h2>
            <div className="valueBox">
              <div className="boxes">
                <img src="/images/trustFair.svg" alt='Trust & Fair Play' />
                <h4>Trust & Fair Play</h4>
                <p>All stakeholders will be treated with respect and no discrimination will be done on the basis of caste, creed, gender or any other parameter. Meritocracy will be the sole guiding light.</p>
              </div>
              <div className="boxes">
                <img src="/images/minimumMonitoring.svg" alt='Minimum Monitoring' />
                <h4>Minimum Integrity</h4>
                <p>Be honest and show a consistent and uncompromising adherence to smoral and ethical principles and values</p>
              </div>
              <div className="boxes">
                <img src="/images/openess.svg" alt='Openness' />
                <h4>Openness</h4>
                <p>Open to valuable inputs from student fraternity</p>
              </div>
            </div>
            <div className="valueBox ">
              <div className="boxes fixHeight">
                <img src="/images/diversity.svg" alt='Diversity & Plurality' />
                <h4>Diversity & Plurality</h4>
                <p>Vision to delve into different disciplines</p>
              </div>
              <div className="boxes fixHeight">
                <img src="/images/allowSchool.svg" alt='Allow Schools to evolve niche' />
                <h4>Allow Schools to evolve niche</h4>
                <p>Enterprising institutions are welcome to participate.</p>
              </div>
              <div className="boxes fixHeight">
                <img src="/images/talent.svg" alt='Talent Recognition' />
                <h4>Talent Recognition</h4>
                <p>It can to bring that talent to its true recognition and worth</p>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section>
        <div className="talentReco">
          <Container>
            <h2>Talent Recognition</h2>
            <div className="talentBox">
              <div>
                <p>ABPUA is about talent recognition and doing whatever it can to bring that talent to its true recognition and worth. Our four-fold methodology comprises of<br /><br />

                  1. Conduct Talent Search Examinations in association with various schools throughout the length and breadth of the country.These Examinations will be held for Higher Secondary (Class XIth and XIIth) students who want to achieve the zenith of education but are constrained in reaching it due to lack of proper guidance and funding.<br /><br />
                  2. A stringent evaluation mechanism developed in-house by ABPUA ensures the deserving candidate is filtered out based on the performance.<br /><br />
                  3. ABPUA refers to this group of students as ‘Nation’s Assets’ in the building. We shall invest in this premium asset class and endeavor to ensure democratic participation of talent from all walks of life. <br /><br />
                  4. Solicit industrialists and individuals to adopt meritorious students state-wise and at a national level.
                </p>
              </div>
              <div>
                <img src='/images/talentRecog.jpg' alt='Talent Recognition' />
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section className='nopaddingTopBot'>
        <div className="bannerGrey">
          <Container className="greyBg">
            <b>Recognising & Empowering Indian Education System at a Global Level</b>
            <div className="btnCtaNew">
              <Link onClick={() => handleClickScroll('contactus')} to={""}>Contact us</Link>
            </div>
          </Container>
        </div>
      </section>


      <section id='media'>
        <div className="mediaCoverage">
          <Container>
            <h2>Media & Coverage</h2>
            <div className="mediaBlock">
              <div>
                <img src='/images/media01.jpg' alt='Amar Ujala' />
              </div>
              <div>
                <img src='/images/media02.jpg' alt='Amar Ujala' />
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section className='bgNew'>
        <div className="contactUs" id='contactus'>
          <Container>
            {/* <div className="contacthalf">
              <div>
                <span>Connect with us</span>
                <h5>We love to hear from you</h5>
                <p>Have any question in mind or want to enquire? Please feel free to contact us through the form or the following details.</p>
              </div>
              <div>
                <div className="twoColumn">
                  <div>
                    <label>Name</label>
                    <input type="text" placeholder='Name' />
                  </div>
                  <div>
                    <div>
                      <label>Mobile Number</label>
                      <input type="text" placeholder='Mobile Number' />
                    </div>
                  </div>
                </div>

                <div className="twoColumn">
                  <div>
                    <label>Email ID</label>
                    <input type="text" placeholder='Email ID' />
                  </div>
                  <div>
                    <div>
                      <label>Subject</label>
                      <input type="text" placeholder='Subject' />
                    </div>
                  </div>
                </div>

                <div className="oneColumn">
                  <div>
                    <label>Message</label>
                    <textarea placeholder='Type your Message' rows={5} />
                  </div>
                </div>
                <div className="formCtaBtn">
                  <button>send message</button>
                  
                </div>



              </div>
            </div> */}
            <div className="mailUs">
              <b>Get in touch</b>
              <a className='buttonMail' href="mailto:contact@abpua.org">Send Message</a>
            </div>

          </Container>
        </div>
      </section>





    </div>
  )
}
export default Homepage;